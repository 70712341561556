/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */

import React, { useState, useReducer, useEffect, useMemo, useCallback } from 'react';
import { useParams, useLocation, useNavigate, Outlet } from 'react-router-dom';
import { useQuery, useReactiveVar } from '@apollo/client';
import { get } from 'lodash';
import { Typography, Button } from '@vartanainc/design-system';

import TruncatedText from '../../components/TruncatedText/TruncatedText';
import { BUYER_TYPE, loanDecision } from '../../constants/common.constants';
import {
  GET_CUSTOMER_BY_NUMBER,
  GET_CUSTOMER_ONLY_BY_NUMBER,
} from '../../graphql/queries/customer';
import { sessionVar } from '../../graphql/cache';

import { CustomerShowContext } from '../../context/CustomerContext';
import { OnlyCustomerContext } from '../../context/OnlyCustomerContext';
import AutoLoad from '../../components/AutoLoad';
// import Button from '../../components/Button';

import crossIcon from '../../assets/cross.svg';
import chevronIcon from '../../assets/chevron.svg';
import { titleize } from '../../utils/helpers';
import { TabsWithRouter } from '../../components/TabbedNavigation';
import { HasPermission } from '../../components/HasPermission/HasPermission';
import usePermissions from '../../utils/hooks/permissions';
import InfoModal from '../../components/Modals/InfoModal';

const notificationInitialState = {
  title: '',
  message: '',
  show: false,
};

function notificationReducer(state = notificationInitialState, { type, payload }) {
  switch (type) {
  case 'show':
    return {
      ...state,
      show: true,
    };
  case 'hide':
    return {
      ...state,
      show: false,
    };
  case 'update':
    return {
      ...state,
      title: get(payload, 'title', state.title),
      message: get(payload, 'message', state.message),
      show: get(payload, 'show', state.show),
    };
  case 'reset':
    return { ...notificationInitialState };
  default:
    return { ...state };
  }
}

export function CustomerShow() {
  const sessionData = useReactiveVar(sessionVar);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const navigate = useNavigate();

  const { companyNumber, contactNumber } = useParams();
  const { state, pathname } = useLocation();
  const [, hasPermission] = usePermissions();
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [notificationStatus, setNotificationStatus] = useReducer(
    notificationReducer,
    notificationInitialState
  );

  const isSummaryTab = window.location.pathname.includes('summary');

  const {
    data: customerData,
    loading: customerLoading,
    refetch: refetchCustomerData,
  } = useQuery(GET_CUSTOMER_ONLY_BY_NUMBER, {
    variables: {
      number: companyNumber,
    },
  });

  const { data: allCustomerData, loading: allCustomerLoading } = useQuery(
    GET_CUSTOMER_BY_NUMBER,
    {
      variables: {
        number: companyNumber,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const selectedCustomer = get(customerData, 'company', {});

  useEffect(() => {
    const companyName = titleize(get(customerData, 'company.name', ''));
    if (companyName) {
      switch (pathname) {
      case `/dashboard/customers/${companyNumber}/summary`:
        setBreadCrumbs([companyName, 'Summary']);
        break;
      case `/dashboard/customers/${companyNumber}/orders`:
        setBreadCrumbs([companyName, 'Order']);
        break;
      case `/dashboard/customers/${companyNumber}/contacts`:
        setBreadCrumbs([companyName, 'Contacts']);
        break;
      case `/dashboard/customers/${companyNumber}/contacts/${contactNumber}`:
        setBreadCrumbs([companyName, 'Contact name']);
        break;
      case `/dashboard/customers/${companyNumber}/contacts/${contactNumber}/edit`:
        setBreadCrumbs([companyName, 'Contact Edit']);
        break;
      case `/dashboard/customers/${companyNumber}/invoices`:
        setBreadCrumbs([companyName, 'invoices']);
        break;
      case `/dashboard/customers/${companyNumber}/payment-schedule`:
        setBreadCrumbs([companyName, 'Payment schedule']);
        break;
      default:
        setBreadCrumbs([companyName]);
      }
    }
  }, [pathname, companyNumber, contactNumber, customerData, setBreadCrumbs]);

  useEffect(() => {
    if (state?.title && state?.message && state?.show) {
      setNotificationStatus({
        type: 'update',
        payload: {
          title: state.title,
          message: state.message,
          show: true,
        },
      });
    }
  }, [state]);

  useEffect(() => {
    if (notificationStatus.show) {
      setTimeout(
        () =>
          setNotificationStatus({
            type: 'reset',
          }),
        10000
      );
    }
  }, [notificationStatus, setNotificationStatus]);

  const customerShowContext = useMemo(
    () => ({
      // needs to be constant across rerenders
      setNotificationStatus,
      refetchCustomerData,
      customerLoading,
    }),
    [refetchCustomerData, customerLoading, setNotificationStatus]
  );

  const addContact = useMemo(() => {
    if (
      pathname.includes('/contacts') &&
      !pathname.includes('/contacts/new') &&
      !pathname.endsWith('edit')
    ) {
      return (
        <HasPermission resource="customer" action="update" customer={selectedCustomer}>
          <Button
            variant="outlined"
            onClick={() => navigate(`/dashboard/customers/${companyNumber}/contacts/new`)}
          >
            Add contact
          </Button>
        </HasPermission>
      );
    }
    return null;
  }, [pathname, companyNumber, navigate, selectedCustomer]);

  const handleCreateOrder = useCallback(
    (selectedCompany) => {
      navigate('/dashboard/orders/new', {
        state: {
          selectedCompany,
        },
      });
    },
    [navigate]
  );

  const createNewOrder = useMemo(() => {
    if (customerLoading || allCustomerLoading) return <></>;

    const appraisalApproved =
      get(customerData, 'company.creditAppraisal.loanDecision', '') === 'approved';
    const appraisalExpired = get(customerData, 'company.creditAppraisal.expired', false);
    const changeRequestExists =
      get(customerData, 'company.creditAppraisal.loanDecision', '') ===
        'pending_review' &&
      get(customerData, 'company.creditAppraisal.creditAppraisalChangeRequest.id', null);
    const directPayEnabled = get(
      customerData,
      'company.vendorAvailablePaymentOptions',
      []
    ).includes('direct');
    const approvedCreditTermsFormatted = get(
      customerData,
      'company.creditAppraisal.approvedCreditTermsFormatted',
      []
    );
    const createOrderEnabled =
      (appraisalApproved &&
        !appraisalExpired &&
        approvedCreditTermsFormatted.length > 0) ||
      directPayEnabled;

    return (
      <HasPermission resource="order" action="create" customer={selectedCustomer}>
        <Button
          BackgroundColor="primary"
          disabled={!createOrderEnabled}
          onClick={() => {
            if (
              changeRequestExists ||
              !appraisalApproved ||
              appraisalExpired ||
              approvedCreditTermsFormatted.length === 0
            )
              setShowPaymentModal(true);
            else
              handleCreateOrder({
                id: get(customerData, 'company.id', ''),
                name: get(customerData, 'company.name', ''),
                number: get(customerData, 'company.number', ''),
              });
          }}
        >
          Create order
        </Button>
      </HasPermission>
    );
  }, [
    customerLoading,
    allCustomerLoading,
    customerData,
    selectedCustomer,
    handleCreateOrder,
  ]);

  const notification = useMemo(() => {
    if (notificationStatus.show) {
      return (
        <div className="shadow rounded bg-vartana-light-green py-4 px-6">
          <div className="flex justify-between">
            <h2 className="vp-card-subtitle-bold text-vartana-dark-green inline-block">
              {notificationStatus.title}
            </h2>
            <button
              className="cursor-pointer"
              onClick={() => setNotificationStatus({ type: 'reset' })}
            >
              <img alt="close" src={crossIcon} />
            </button>
          </div>
          <p className="vp-body text-vartana-dark-green mt-2">
            {notificationStatus.message}
          </p>
        </div>
      );
    }
    return null;
  }, [notificationStatus]);

  const showApplicationTab = useMemo(() => {
    return (
      get(customerData, 'company.creditAppraisal.loanDecision', '') ===
        loanDecision.needInformation &&
      !get(customerData, 'company.creditAppraisal.expired', false)
    );
  }, [customerData]);

  const loggedInCompany = get(sessionData, 'session.user.company', {});
  const buyerType = get(selectedCustomer, 'buyerRelationToVendor');

  const tabsList = useMemo(() => {
    let tabs = [
      { name: 'Summary', path: 'summary' },
      { name: 'Orders', path: 'orders' },
      { name: 'Contacts', path: 'contacts' },
      { name: 'Invoices', path: 'invoices' },
      { name: 'Payment schedule', path: 'payment-schedule' },
    ];

    if (showApplicationTab) tabs.push({ name: 'Track', path: 'track' });

    if (!hasPermission('order', 'view', selectedCustomer))
      tabs = tabs.filter((tab) => tab.path !== 'orders');

    if (buyerType === BUYER_TYPE.INDIRECT) {
      tabs = tabs.filter((tab) => tab.path !== 'contacts');
    } else if (
      loggedInCompany.useResellerWorkflow &&
      loggedInCompany.hideResellerOrderDetails
    ) {
      tabs = tabs.filter(
        (tab) => !['payment-schedule', 'invoices'].includes(tab.path)
      );
    }

    return tabs;
  }, [
    showApplicationTab,
    hasPermission,
    selectedCustomer,
    buyerType,
    loggedInCompany.useResellerWorkflow,
    loggedInCompany.hideResellerOrderDetails,
  ]);

  const title = useMemo(() => {
    const breadCrumbTitles = breadCrumbs.map((breadCrumb, index) => (
      <div key={breadCrumb} className="flex gap-2 items-center h-[6.375rem]">
        {index === 0 ? (
          <TruncatedText
            variant="heading24"
            color="color-black-100"
            className="capitalize"
            text={breadCrumb}
            maxChar={30}
          />
        ) : (
          <Typography variant="heading24" color="color-black-100" className="capitalize">
            {breadCrumb}
          </Typography>
        )}
        {index !== breadCrumbs.length - 1 ? (
          <img alt="chevron" className="inline-block" src={chevronIcon} />
        ) : null}
      </div>
    ));
    return <div className="flex space-x-2">{breadCrumbTitles}</div>;
  }, [breadCrumbs]);

  return (
    <>
      <div className="flex flex-col bg-gray-50 h-screen">
        <div className="w-full flex px-8 border-b border-gray-200 justify-between bg-white">
          <div className="flex justify-between align-middle break-all">{title}</div>

          <div className="flex lg:justify-end items-center gap-4 max-w-1/2 text-right">
            {addContact}
            {createNewOrder}
          </div>
        </div>

        <CustomerShowContext.Provider value={customerShowContext}>
          <OnlyCustomerContext.Provider value={{ ...customerData }}>
            <AutoLoad
              loading={isSummaryTab ? customerLoading : allCustomerLoading}
              containerClassName="flex justify-center"
              className="absolute text-center top-2/4 transform-gpu -translate-y-2/4"
            >
              {notification}
              <TabsWithRouter tabs={tabsList} />
              <div className="flex-1 bg-vartana-steel-20 overflow-x-auto">
                <Outlet
                  context={{
                    customerData: allCustomerData,
                    customerLoading: allCustomerLoading,
                    companyNumber,
                  }}
                />
              </div>
            </AutoLoad>
          </OnlyCustomerContext.Provider>
        </CustomerShowContext.Provider>
      </div>

      <InfoModal
        title="Payment plans are almost ready"
        confirmButtonText="Yes, continue without financing"
        rejectButtonText="No, go back"
        description={(
          <Typography variant="paragraph14">
            We are working diligently to approve your customer for Vartana financing.
            <br />
            Would you like to proceed without Vartana financing for this order?
          </Typography>
        )}
        isOpen={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onSubmit={() => {
          handleCreateOrder({
            id: get(customerData, 'company.id', ''),
            name: get(customerData, 'company.name', ''),
            number: get(customerData, 'company.number', ''),
          });
        }}
      >
      </InfoModal>
    </>
  );
}

export default CustomerShow;
